<template>
	<van-overlay :show="protocolShow" @click="protocolShow = false" :lock-scroll="false">
		<div class="protocol-wrapper " @click.stop>
			<div class="protocol-box  relative flex-col">
				<div>
					<van-icon name="close" class="close-icon font_color_g_99" @click="$router.back" size="24">
					</van-icon>
					<h1 class="protocol-title">《用户协议和法律协议》</h1>
				</div>
				<div class="protocol-context" v-html="protocolText">
				</div>
				<div class="protocol-footer">
					<van-checkbox v-model="checkedProtocol" class="check-protocol">我已同意《用户协议和法律协议》
					</van-checkbox>
					<button class='button button-agree' :disabled="!checkedProtocol" @click="handleAgree">
						我已阅读并同意
					</button>
				</div>
			</div>
		</div>
	</van-overlay>
</template>

<script>
import { Toast } from 'vant'

export default {
	name: 'Protocol',
	props: {
		visible: {
			type: Boolean,
			default: false
		},
		userCode: {
			type: [String, Number],
			default: ''
		}
	},
	data() {
		return {
			checkedProtocol: false,
			protocolText: '',
			pageLoading: true,
			BaseUrl: process.env.VUE_APP_PROTOCOL_PATH
		}
	},
	created() {
		this.getProtocolText()
	},
	computed: {
		protocolShow: {
			set(val) {
				this.$emit('update:visible', val)
			},
			get() {
				return this.visible
			}
		}
	},
	methods: {

		async getProtocolText() {
			const toast = Toast.loading({
				duration: 0,
				forbidClick: true,
				message: '加载中...'
			})
			try {
				const response = await fetch(`${this.BaseUrl}CommonAgreement/GetCommonAgreement`)
				const data = await response.json()
				this.protocolText = data.data
				toast.clear()
			} catch (e) {
				toast.clear()
				console.warn(e)
			}
		},
		async handleAgree() {
			try {
				this.protocolShow = false
				this.$emit('agree')
				const response = await fetch(`${this.BaseUrl}CommonAgreement/AddAgree`, {
					method: 'POST', // *GET, POST, PUT, DELETE, etc.
					mode: 'cors', // no-cors, *cors, same-origin
					cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
					credentials: 'same-origin', // include, *same-origin, omit
					headers: {
						'Content-Type': 'application/json'
						// 'Content-Type': 'application/x-www-form-urlencoded',
					},
					redirect: 'follow', // manual, *follow, error
					referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
					body: JSON.stringify({
						userCode: this.userCode,
						projectCode: 'yh',
						projectName: '云货'
					})
				})
			} catch (e) {
				console.warn(e)
			}

		}
	}
}
</script>

<style lang="scss" scoped>
// 协议弹窗
.protocol-wrapper {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;

	.protocol-box {
		position: relative;
		width: 90vw;
		height: 80vh;
		background-color: #fff;
		border-radius: 0.1rem;
		display: flex;
		flex-direction: column;
		align-items: center;
		padding: 15px 10px;

		.close-icon {
			position: absolute;
			top: 0.1rem;
			right: 0.1rem;
		}

		.protocol-title {
			font-size: 18px;
			font-weight: bold;
			padding-bottom: 8px;
		}

		.button-agree {
			color: white;
			width: 220px;
			height: 40px;
			background: $color-primary;
			border-radius: 20px;
		}


	}

	.protocol-context {
		overflow-y: auto;
		width: 100%;
		flex: 1;
	}

	.protocol-footer {
		.check-protocol {
			margin-top: 10px;
		}

		.button-agree {
			margin-top: 10px;
		}
	}

}

</style>
