<template>
    <div class="page">
        <div class="login_header">
            还差<span class="font-size28">1</span>步，即可登录成功
        </div>
        <div class="login-context">
            <div class="text-center mt-4 fs-12 color-gray">ID:{{ userInfo.userCode }}</div>
            <transition name="van-fade" mode="out-in">
                <verify-code v-if="showVerifyCode" :soNumber="soNumber"
                             @verifySuccess="handleVerifySuccess"/>
                <div v-else class="mt-4 flex column">
                    <div v-if="isEncodeError" class="flex column">
                        <img
                                class="encode-error"
                                referrerpolicy="no-referrer"
                                src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngb9fb7675f2537c445a8957bc2d60d540d065ef7055976961bb1fd5e6e0990ea6"
                        />
                        <p class="color-gray fs-12 mt-2">二维码生成失败</p>
                    </div>
                    <div v-else class="relative">
                        <img :src="headImg" alt="" class="encode">
                        <template v-if="!resultData.scanCodeUrl">
                            <div class="login_avatar_txt flex h-center fs-12">
                                生成二维码中...
                            </div>
                            <div class="loadEffect">
                                <span v-for="i in 8" :key="i"/>
                            </div>
                        </template>
                    </div>
                    <!--有二维码展示-->
                    <template v-if="resultData.scanCodeUrl">
                        <div class="wx-tip mb-5" v-if="isWx">
                            <p>长按二维码转发给好友</p>
                            <p>本机微信扫码完成绑定</p>
                        </div>
                        <!--						<button class="login_refresh wx-refresh button flex h-center fs-16" @click="refreshs">
                                      {{ isEncodeError ? '重新生成二维码' : '刷新二维码' }}
                                    </button>-->
                        <template v-else>
                            <!--判断是否有原生方法-->
                            <template v-if="hasShareImg">
                                <p class="b login-tip color-warning">不支持长按扫码，或微信扫本地相册登录</p>
                                <button class="login_share button flex h-center" @click="handleShare"
                                        :disabled="!resultData.scanCodeUrl">
                                    <van-icon name="wechat" size="24" class="mr-1"/>
                                    发送给附近好友
                                </button>
                            </template>
                        </template>
                    </template>
                    <button v-if="resultData.scanCodeUrl || isEncodeError" :class="{ 'wx-refresh' : isWx}"
                            class="login_refresh button flex h-center fs-16" @click="refreshs">
                        {{ isEncodeError ? '重新生成二维码' : '刷新二维码' }}
                    </button>
                    <div class="tutorial fs-12 color-gray mt-4" v-if="resultData.scanCodeUrl && !isEncodeError">
                        <b class="b color-primary">10万+</b>店主已完成扫码绑定，立即扫码登录即可拥有
                      <p class="color-blue fs-12" @click="tutorialVisible = true">查看教程</p>
                    </div>
                </div>

            </transition>
        </div>
        <popup-remind v-model="tutorialVisible"/>
        <Protocol v-model:visible="protocolVisible" @agree="getPort" :userCode="userInfo.userCode"/>


    </div>
</template>

<script>
export default {
    name: 'login'
}
</script>
<script setup>
import {computed, onMounted, onUnmounted, ref, toRaw} from 'vue'
import {useStore} from 'vuex'
import {Dialog, Notify, Toast} from 'vant'
import template from '../../assets/template.png'
import {ApiGetPorts, ApiGetWxLoginResult, ApiWxRobotLogin} from '../../api/WxRobot'
import {useRouter} from 'vue-router'
import {Platform} from '../../utils'
import Protocol from '../../components/Protocol'
import VerifyCode from '../../components/VerifyCode.vue'

import PopupRemind from './ThePopupRemind'

const store = useStore()
const router = useRouter()
// 用户信息
const userInfo = computed(() => store.getters.userInfo)
// 机器人信息
const robot = computed(() => store.getters.robotInfo)
// 登录编号
const soNumber = ref()
// 定时器编号
const loginTimer = ref()
// 验证码展示
const showVerifyCode = ref(false)
// 协议
const protocolVisible = ref(true)
const headImg = computed(() => {
    const {scanCodeUrl} = resultData.value
    return scanCodeUrl ? scanCodeUrl : template
})
// 判断是否在微信环境下
const isWx = Platform.IsWX()
const hasShareImg = !!window.shareImg
const resultData = ref({
    eventCode: 0,
    scanCodeUrl: ''
})
// 教程弹窗
const tutorialVisible = ref(false)
// 错误二维码
const isEncodeError = ref(false)
const encode = ref('')
const second = ref(false)

const loginWx = async () => {
    try {
        const data = toRaw(robot.value)
        isEncodeError.value = false
        soNumber.value = await ApiWxRobotLogin({
            // 设备号
            deviceId: data.deviceId,
            // 机器人编号
            robotWxId: data.robotWxId,
            // 过期时间
            expireDate: data.expireDate,
            // 客户端IpclientIp
            clientIp: data.clientIp,
            // 1个人微信,2企业微信
            robotWxType: data.robotWxType
        })
        timeControl()
    } catch (e) {
        await Dialog.alert({
            title: '系统提示',
            message: e.message
        })
            .then(() => {
                router.back()
            })
    }
}

const timeControl = () => {

    getEncode()
    clearInterval(loginTimer.value)
    loginTimer.value = setInterval(async () => {
        await getEncode()
    }, 2000)
}

const getEncode = async () => {
    try {
        let res = await ApiGetWxLoginResult(soNumber.value)
        if (res && res.state) {
            resultData.value = {
                scanCodeUrl: res.qrUrl
            }
            const msgType = {
                '-1': res.logoutReason,
                '0': '获取二维码失败',
                '1': '',
                '2': '',
                '3': `登录异常,${res.logoutReason}`,
                '5': '',
                '6': '登录失败'
            }

            let msg = msgType[res.state]
            // let msg = msgType['0']
            switch (res.state) {
                case '-1':
                    clearInterval(loginTimer.value)
                    Dialog.confirm({
                        title: '二维码已失效',
                        message: msg
                    })
                        .then(() => {
                            loginWx()
                        })
                        .catch(() => {
                            // on cancel
                        })
                    return
                case '2':
                    clearInterval(loginTimer.value)
                    Dialog.alert({
                        title: '提示',
                        message: '登录成功'
                    })
                        .then(() => {
                            // on close
                        })
                    await router.replace('/index')
                    break
                case '1081':
                    clearInterval(loginTimer.value)
                    loginTimer.value = null
                    showVerifyCode.value = true
                    break
                default:
            }

            if (msg) {
                if (res.state === '0') {
                    isEncodeError.value = true
                }
                clearInterval(loginTimer.value)
                Dialog.alert({
                    title: '提示',
                    message: msg
                })
                    .then(() => {
                    })
            }
        }
    } catch (e) {
        clearInterval(loginTimer.value)
    }
}
const handleShare = () => {
    const bean = {
        imgUrl: resultData.value.scanCodeUrl
    }
    window.shareImg.postMessage(
        JSON.stringify(bean)
    )
}

const handleVerifySuccess = () => {
    timeControl();
    showVerifyCode.value = false
}
// 刷新页面时调用  防止用户已经登录成功 重复拉取二维码
const getPort = async () => {
    try {
        const res = await ApiGetPorts()
        const robot = res[0]
        // 如果 robotWxId 存在证明已经登录成功
        if (robot.robotWxId && robot.state === 1) {
            await router.replace('/index')
            return
        }
        store.commit('resetRobotInfo')
        await store.dispatch('setRobotInfo', robot)

        await loginWx()
    } catch (e) {
        console.warn(e)
    }

}
const refreshs = async () => {
    Toast('刷新成功！')
    await getPort()
}

onMounted(async () => {
    // await getPort()
})
onUnmounted(() => {
    clearInterval(loginTimer.value)
})
</script>

<style lang="scss" scoped>
.page {
  background: #FFFFFF;
  padding-bottom: 20px;
}

.tutorial {
  text-align: center;
  width: 100%;
}

.encode-error {
  width: 48px;
  height: 48px;
}

.font-size28 {
  font-size: 28px;
}

.login_header {
  background: url("assets/img_login_bg.png") no-repeat;
  width: 100%;
  height: 100px;
  background-size: contain;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  color: #FFFFFF;
  padding-top: 24px;
  box-sizing: border-box;
}

.login_share, .login_refresh {
  color: white;
  width: 188px;
  height: 40px;
  border-radius: 4px 4px 4px 4px;
}

.login_share {
  margin-top: 50px;
  color: white;
  background: #06C15F;
}

.login_refresh {
  margin-top: 12px;
  color: #3C3C3C;
  width: 188px;
  height: 40px;
  background: white;
  border: 1px solid #EEEEEE;
}

.wx-refresh {
  margin-top: 35px;
  background: #EEEEEE;

}

.wx-tip {
  margin-top: 24px;
  color: #FF445E;

  p {
    font-size: 16px;
    font-weight: bold;
  }
}

.login-context {
  margin-top: 88px;

  .login-tip {
    margin-top: 40px;
  }


}


.avatar {
  width: px(148);
  height: px(148);
  background: rgba(0, 0, 0, 0.4);
  border-radius: px(80);
  border: px(4) solid #FF445E;
}

.encode {
  width: px(148);
  height: px(148);
}

.loadEffect {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: 100px;
  height: 100px;
}

.loadEffect span {
  display: inline-block;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: #48a5f4;
  position: absolute;
  // animation: load 1.04s ease infinite;
  -webkit-animation: load 1.04s ease infinite;
}

@-webkit-keyframes load {
  0% {
    opacity: 1;
    background-color: #000 !important;
  }

  100% {
    opacity: 0.2;
    background-color: #000 !important;
  }
}

.loadEffect span:nth-child(1) {
  left: 0;
  top: 50%;
  margin-top: px(-8);
  -webkit-animation-delay: 0.13s;
}

.loadEffect span:nth-child(2) {
  left: px(14);
  top: px(14);
  -webkit-animation-delay: 0.26s;
}

.loadEffect span:nth-child(3) {
  left: 50%;
  top: 0;
  margin-left: px(-8);
  -webkit-animation-delay: 0.39s;
}

.loadEffect span:nth-child(4) {
  top: px(14);
  right: px(14);
  -webkit-animation-delay: 0.52s;
}

.loadEffect span:nth-child(5) {
  right: 0;
  top: 50%;
  margin-top: px(-8);
  -webkit-animation-delay: 0.65s;
}

.loadEffect span:nth-child(6) {
  right: px(14);
  bottom: px(14);
  -webkit-animation-delay: 0.78s;
}

.loadEffect span:nth-child(7) {
  bottom: 0;
  left: 50%;
  margin-left: px(-8);
  -webkit-animation-delay: 0.91s;
}

.loadEffect span:nth-child(8) {
  bottom: px(14);
  left: px(14);
  -webkit-animation-delay: 1.04s;
}

.login_avatar_txt {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: px(148);
  height: px(148);
  font-size: px(16);
  color: #fff;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.3);
}


</style>
