<template>
	<van-overlay :show="myVisible"
	             bind:click="onClickImgHide">
		<div class="popupRemind"
		     style="background: unset">
			<div class="context flex column align-unset">
				<card class="w100 flex column" background="#F6F5F5">
					<div class="b fs-16">第一步，<span class="color-primary">分享</span>二维码到附近设备</div>
					<img src="./assets/img_guide_1.png" class="mt-2 login_guide__img" alt="">
				</card>
				<card class="w100 flex column mt-8" background="#F6F5F5">
					<div class="b share text-center">
						<p class="fs-16">第二步，用当前手机，<span class="color-primary">面对面</span>扫</p>
						<p class="fs-16"><span class="color-primary">附近</span>设备上的二维码</p>
					</div>
					<img src="./assets/img_guide_2.png" class="mt-2 login_guide__img" alt="">
				</card>
			</div>

			<div class="flex h-center mt-2"
			     @click="handleClose">
				<img src="../../assets/icon_close.png" class="icon_close"/>
			</div>
		</div>

	</van-overlay>
</template>

<script>
import  Card from '../../components/Card'
export default {
	name: 'PopupRemind',
	components: {
		Card
	},
	props: {
		modelValue: {
			type: Boolean,
			default: false
		},
		imgUrl: {
			type: String,
			default: ''
		},
		imgWidth: {
			type: String,
			default: ''
		},
		imgHeight: {
			type: String,
			default: ''
		}
	},
	emits: ['update:modelValue'],
	computed: {
		myVisible: {
			set(val) {
				this.$emit('update:modelValue', val)
			},
			get() {
				return this.modelValue
			}
		}
	},
	methods: {
		handleClose() {
			this.myVisible = false
		},
		handleTarget() {
			this.handleClose()
			this.$router.push('/friendCircle')
		}
	}
}
</script>

<style scoped lang="scss">
.text-gray {
	color: #433C3C;
}
.popupRemind {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 305px;
	border-radius: px(4);
	background-color: #fff;
	.context {
		width: 292px;
		//height: 274px;
		background: #F6F5F5;
		border-radius: 16px;
		padding: 20px 25px;
		box-sizing: border-box;

		.context-bg {
			width: 100%;
			height: 114px;
		}
		.login_guide__img{
			width: 253px;
			height: 169px;
		}
		.context-text {

		}

		.context-button {
			align-self: center;
			width: 152px;
			height: 40px;
			background: #FFFFFF;
			border-radius: 20px;
			border: 1px solid #FF545B;
			font-size: 14px;
			font-weight: 500;
			color: #FF545B;
		}
	}
}


.remind_img {
	width: px(300);
	height: px(305);
}

.icon_close {
	width: px(32);
	height: px(32);
}

</style>
